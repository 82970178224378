<template>
  <div class="pb-12">
    <div class="px-4" style="display: flex; justify-content: end">
      <v-icon size="36" color="pink" @click="toggleModal"
        >mdi-fullscreen</v-icon
      >
    </div>
    <div class="pa-4" v-if="serverData">
      <div>
        <div class="dashboard-top-container w-100">
          <div
            v-for="(counter, k) in counters"
            :key="counter.title"
            class="dashboard-top-card"
          >
            <v-card
              elevation="0"
              hover
              class="d-flex flex-column justify-content-between dashboard-top-card-inner"
              :class="getRandomColors(k)"
              style=""
            >
              <v-card-text class="dashboard-top-card-title" style="">
                {{ counter.title }}
              </v-card-text>

              <v-card-text class="dashboard-top-card-data" style="">
                <div>{{ counter.value1 }}</div>
                <div class="pt-2">{{ counter.value2 }}</div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
      <div class="bg-white pa-2 table-card">
        <div class="card-header flex-wrap border-0 ma-0 pa-0">
          <div class="card-title ma-0 pa-0 w-100">
            <h1 class="poppins card-label ps-2">
              <span
                class="text-dark-65 display4-md font-weight-bolder page-title"
              >
                {{ table_properties.title }}
              </span>
              <span class="d-block text-dark-50 mt-1 page-desc">{{
                table_properties.description
              }}</span>
            </h1>
          </div>
        </div>

        <div class="dashboard-table">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-black-50 table-head-th"
                    v-for="head in headers"
                    :key="head"
                  >
                    {{ head }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in items" :key="i" class="text-black-50">
                  <td
                    v-for="(part, j) in item"
                    :key="j"
                    style=""
                    class="table-body-td"
                  >
                    <div v-if="j == 2">
                      <StatusColumn
                        :value="part"
                        custom-class="text-center badge py-2 px-3 my-0"
                      />
                    </div>
                    <div v-else>{{ part }}</div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" fullscreen persistent>
      <div style="background-color: #EBEDF3; min-height: fit-content; height: 100vh; width: 100%;">
        <div class="px-4 pt-2" style="display: flex; justify-content: end">
          <v-icon x-large color="pink" @click="toggleModal"
            >mdi-fullscreen-exit</v-icon
          >
        </div>
        <div class="pa-4" v-if="serverData">
          <div>
            <div class="dashboard-top-container w-100">
              <div
                v-for="(counter, k) in counters"
                :key="counter.title"
                class="dashboard-top-card"
              >
                <v-card
                  elevation="0"
                  hover
                  class="d-flex flex-column justify-content-between dashboard-top-card-inner"
                  :class="getRandomColors(k)"
                  style=""
                >
                  <v-card-text class="dashboard-top-card-title" style="">
                    {{ counter.title }}
                  </v-card-text>

                  <v-card-text class="dashboard-top-card-data" style="">
                    <div>{{ counter.value1 }}</div>
                    <div class="pt-2">{{ counter.value2 }}</div>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </div>
          <div class="bg-white pa-2 table-card">
            <div class="card-header flex-wrap border-0 ma-0 pa-0">
              <div class="card-title ma-0 pa-0 w-100">
                <h1 class="poppins card-label ps-2">
                  <span
                    class="text-dark-65 display4-md font-weight-bolder page-title"
                  >
                    {{ table_properties.title }}
                  </span>
                  <span class="d-block text-dark-50 mt-1 page-desc">{{
                    table_properties.description
                  }}</span>
                </h1>
              </div>
            </div>

            <div class="dashboard-table">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-black-50 table-head-th"
                        v-for="head in headers"
                        :key="head"
                      >
                        {{ head }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in items"
                      :key="i"
                      class="text-black-50"
                    >
                      <td
                        v-for="(part, j) in item"
                        :key="j"
                        style=""
                        class="table-body-td"
                      >
                        <div v-if="j == 2">
                          <StatusColumn
                            :value="part"
                            custom-class="text-center badge py-2 px-3 my-0"
                          />
                        </div>
                        <div v-else>{{ part }}</div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import StatusColumn from "@/own/components/datatable/StatusColumn";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import getPusherInstance from "@/core/services/pusher.service";
export default {
  components: { StatusColumn },
  name: "DashboardAsn",
  data: () => ({
    dialog: false,
    headers: [],
    items: [],
    counters: [],
    serverData: null,
    pusher: null,
    channel: null,
    bgColors: [
      "bg-info text-info",
      "bg-primary text-primary",
      "bg-danger text-danger",
      "bg-warning text-warning",
      "bg-success text-success",
    ],
    table_properties: {
      title: "ASN Dashboard",
      description: "Dashboard for ASN",
    },
  }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.loadInitialDataFromServer();
  },
  beforeDestroy() {
    if (this.pusher) {
      if (this.channel) {
        this.channel.unbind_all();
      }
      this.pusher.unsubscribe(this.userSocketData.channel);
    }
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    getRandomColors: function (i) {
      return this.bgColors[i % 5];
    },
    loadInitialDataFromServer() {
      let filterData = {
        date_range_type: "custom",
        date_range: ["2021-01-15", "2021-02-14"],
      };
      ApiService.post("/dashboards/dashboardAsn", filterData)
        .then((response) => {
          this.serverData = response.data;
          this.counters = response.data.counters;
          this.headers = response.data.datatable.header;
          this.items = response.data.datatable.body;
          this.pusher = getPusherInstance();
          this.channel = this.pusher.subscribe('dashboard.asn');
          this.initSocketConnection(response.data.event);
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    initSocketConnection: function (eventName) {
      this.channel.bind(`${eventName}`, (notification) => {
        // console.log("socket data:", notification);
        this.serverData = notification;
        this.counters = notification.counters;
        this.headers = notification.datatable.header;
        this.items = notification.datatable.body;
      });
    },
  },
  computed: {
    userSocketData: function () {
      /**
       * @type {{key:string,wsHost:string,wsPort:number,wssPort:number,channel:string,event:string}}
       * @example {"key":"BKFNMJNMGF","wsHost":"apitest.iqfulfillment.com","wsPort":6001,"wssPort":6001,"channel":"user.127","event":".notification.created"}
       */
      const socketData = this.$store.getters.getSocketData;
      return socketData;
    },
  },
};
</script>
<style scoped>
tr:nth-child(even) {
  background-color: #efefef;
}

.dashboard-top-container {
  display: grid;
  column-gap: 8px;
  row-gap: 8px;
  grid-column: 1;
}

.dashboard-top-card {
  width: 100%;
  height: 140px;
}
.dashboard-top-card-inner {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: white;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.5)
  );
  backdrop-filter: blur(1rem);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
}
.dashboard-top-card-title {
  color: white !important;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.1;
  word-wrap: break-word;
}
.dashboard-top-card-data {
  color: white !important;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.1;
  word-wrap: break-word;
}

.page-title {
  font-size: 16px !important;
}
.page-desc {
  font-size: 10px !important;
}

.table-head-th {
  font-weight: bold;
  font-size: 16px;
}
.table-body-td {
  font-size: 12px;
}

.table-card {
  margin-top: 10px;
  border-radius: 12px;
}

@media (min-width: 768px) {
  .dashboard-top-container {
    display: grid;
    column-gap: 8px;
    row-gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  }
  .dashboard-top-card {
    height: 140px;
  }
  .dashboard-top-card-title {
    font-size: 19px;
  }
  .dashboard-top-card-data {
    font-size: 23px;
  }

  .page-title {
    font-size: 20px !important;
  }
  .page-desc {
    font-size: 14px !important;
  }
  .table-head-th {
    font-size: 12px !important;
  }
  .table-body-td {
    font-size: 12px;
  }
  .table-card {
    margin-top: 10px;
  }
}

@media (min-width: 820px) {
  .dashboard-top-container {
    display: grid;
    column-gap: 8px;
    row-gap: 8px;
    grid-template-columns: repeat(4, 1fr);
  }
  .dashboard-top-card {
    height: 140px;
  }
  .dashboard-top-card-inner {

  }
  .dashboard-top-card-title {
    font-size: 14px;
  }
  .dashboard-top-card-data {
    font-size: 20px;
  }

  .page-title {
    font-size: 24px !important;
  }
  .page-desc {
    font-size: 16px !important;
  }
  .table-head-th {
    font-size: 16px !important;
  }
  .table-body-td {
    font-size: 16px !important;
  }
  .table-card {
    margin-top: 10px;
  }
}

@media (min-width: 1465px) {
  .dashboard-top-container {
    display: grid;
    column-gap: 8px;
    row-gap: 8px;
    grid-template-columns: repeat(4, 1fr);
  }
  .dashboard-top-card {
    height: 190px;
  }
  .dashboard-top-card-title {
    font-size: 22px;
  }
  .dashboard-top-card-data {
    font-size: 30px;
  }

  .page-title {
    font-size: 24px !important;
  }
  .page-desc {
    font-size: 16px !important;
  }
  .table-head-th {
    font-size: 16px !important;
  }
  .table-body-td {
    font-size: 16px !important;
  }
  .table-card {
    margin-top: 10px;
  }
}

@media (min-width: 2560px) {
  .dashboard-top-container {
    display: grid;
    column-gap: 8px;
    row-gap: 8px;
    grid-template-columns: repeat(4, 1fr);
  }
  .dashboard-top-card {
    height: 240px;
  }
  .dashboard-top-card-inner {
    padding: 10px;
  }
  .dashboard-top-card-title {
    font-size: 34px;
  }
  .dashboard-top-card-data {
    font-size: 42px;
  }

  .page-title {
    font-size: 32px !important;
  }
  .page-desc {
    font-size: 22px !important;
  }
  .table-head-th {
    font-size: 22px !important;
  }
  .table-body-td {
    font-size: 22px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .table-card {
    margin-top: 20px;
  }
}

@media (min-width: 3840px) {
  .dashboard-top-container {
    display: grid;
    column-gap: 20px;
    row-gap: 12px;
    grid-template-columns: repeat(4, 1fr);
  }
  .dashboard-top-card {
    height: 340px;
  }
  .dashboard-top-card-inner {
    padding: 18px;
    border-radius: 20px;
  }
  .dashboard-top-card-title {
    font-size: 50px;
  }
  .dashboard-top-card-data {
    font-size: 76px;
  }

  .page-title {
    font-size: 44px !important;
  }
  .page-desc {
    font-size: 28px !important;
  }
  .table-head-th {
    font-size: 32px !important;
  }
  .table-body-td {
    font-size: 32px !important;
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .table-card {
    margin-top: 30px;
    padding-top: 20px !important;
  }
  .dashboard-table {
    margin-top: 20px;
  }
}
</style>
